/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.title-reason {
  display: flex;
}
.title-reason > .title-icon {
  margin-right: 8px;
}
.title-reason > .title-content {
  align-self: center;
}
.title-reason > .title-content > span {
  font-weight: bold;
}
