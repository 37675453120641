/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.mycard {
  display: flex;
  justify-content: center;
}
.action-panel {
  margin-top: 20px;
}
.mycard-with-details ul.mycard-details > li .label {
  min-width: 100px;
}
